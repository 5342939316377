<script>
	import { searchPromptDialog } from '$lib/stores/searchPrompt';
	import { appStores } from '$lib/stores/appStores';
	import { Translator } from '$lib/js/translations/Translator';
	import { globalAlert } from '$lib/stores/globalAlert';

	const T = new Translator($appStores.language, [
		// Page translations
		{ en: 'Please select a value', 'zh-tw': '請選擇一個值' },
		{ en: 'Submit', 'zh-tw': '提交' },
		{ en: 'Cancel', 'zh-tw': '取消' },
	]);

	import SlotModal from '$lib/components/Modals/SlotModal.svelte';
	import Button from '$lib/components/Controls/Button.svelte';
	import SearchDropdown from '$lib/components/Controls/SearchDropdown.svelte';

	let open = '';
	let message = '';
	let title = '';
	let label = '';
	let placeholder = '';
	let resolve;
	let items;
	let value;

	let mods = undefined;

	// Mods:
	// - labelFieldName
	// - valueFieldName
	// - confirmText
	// - confirmButtonClass
	// - cancelText
	// - cancelButtonClass
	// - iconHtml
	// - topComponentSlot
	// - topComponentSlotProps

	searchPromptDialog.subscribe((val) => {
		if (val !== null) {
			open = true;
			message = val.message;
			items = val.items;
			title = val.title;
			label = val.label;
			placeholder = val.placeholder;
			mods = val.mods;
			value = val?.mods?.value;
			resolve = val.resolve;
		} else {
			open = false;
			message = null;
			title = null;
			label = null;
			placeholder = null;
			mods = undefined;
			resolve = null;
		}
	});

	const handleCancel = () => {
		resolve(undefined);
		searchPromptDialog.set(null);
	};

	function handleConfirm() {
		// Must be a valid number
		if (!value) {
			return globalAlert.set({
				message: T.out('Please select a value'),
				type: 'error',
			});
		}

		resolve(value);
		searchPromptDialog.set(null);
	}
</script>

<SlotModal
	modalClasses="modal modal-md"
	zIndex="999"
	{title}
	bind:show={open}
	on:outsideClick={handleCancel}
	on:closeButtonClick={handleCancel}
>
	<form on:submit|preventDefault|stopPropagation={handleConfirm} class="flex w-full flex-col gap-3 py-3">
		{#if mods?.iconHtml}
			<div class="grid content-center py-4 px-4 text-center">
				{@html mods.iconHtml}
			</div>
		{/if}
		{#if mods?.topComponentSlot}
			<div class="grid content-center py-4 px-4 text-center">
				<svelte:component this={mods?.topComponentSlot} {...mods?.topComponentSlotProps ?? {}} />
			</div>
		{/if}
		<div class="grid content-center py-2 px-4 text-center">
			{@html message}
		</div>
		<div class="grid content-center px-2 pb-2 text-center">
			<SearchDropdown
				{label}
				{placeholder}
				{items}
				labelFieldName={mods?.labelFieldName ?? 'label'}
				valueFieldName={mods?.valueFieldName ?? 'value'}
				allowNonItem={false}
				bind:value
			/>
		</div>

		<div class="grid w-full grid-cols-2 gap-4 px-2">
			<div>
				<Button type="submit" class="w-full {mods?.confirmButtonClass ?? 'btn-md btn-blue btn-solid w-full'}">
					{mods?.confirmText ?? T.out('Submit')}
				</Button>
			</div>
			<div>
				<Button on:click={handleCancel} class="w-full {mods?.cancelButtonClass ?? 'btn-md btn-light-gray btn-text-black w-full'}">
					{mods?.cancelText ?? T.out('Cancel')}
				</Button>
			</div>
		</div>
	</form>
</SlotModal>
