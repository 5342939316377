<script>
	import { onMount, tick } from 'svelte';
	import { page } from '$app/stores';
	import { globalTopBanner } from '$lib/stores/globalTopBanner';
	import { browser } from '$app/env';

	import CircleIcon from '$lib/components/Icons/CircleIcon.svelte';
	import CloseIcon from '$lib/components/Icons/CloseIcon.svelte';

	$: browser && $globalTopBanner?.message && triggerBanner($globalTopBanner);

	let show = false;
	let color = 'blue';
	let message = undefined;
	let showPulseCircle = false;
	let showClose = false;
	let sticky = true;

	let timeout = undefined;
	let offsetStyle = '';

	// Curreny scroll position

	let y;
	let floating = false;
	let offsetHeight;

	$: {
		floating = y > 80;
	}

	// FUNCTIONS

	const triggerBanner = (bannerConfig) => {
		if (!bannerConfig?.message?.length) {
			return;
		}

		color = bannerConfig.color ?? 'blue';
		message = bannerConfig.message;
		showClose = bannerConfig.showClose ?? false;
		showPulseCircle = bannerConfig.showPulseCircle ?? false;
		$globalTopBanner = {};

		openBanner({ ...bannerConfig });
	};

	export const openBanner = (opts) => {
		// Clear the alert store
		$globalTopBanner = {};

		// Set the alert
		color = opts.color ?? 'blue';
		message = opts.message;
		showPulseCircle = opts.showPulseCircle ?? false;
		showClose = opts.showClose ?? false;
		show = true;

		// Optionally close on end of timeout
		if (timeout) setTimeout(() => (show = false), timeout);

		tick().then(() => {
			if (document?.getElementById('global-top-banner')) {
				offsetHeight = document?.getElementById('global-top-banner')?.clientHeight;
			}
		});
	};

	export const closeBanner = () => {
		show = false;
		$globalTopBanner = {};
	};

	const sharedBodyClasses = `flex p-3 rounded-lg z-50 transition-opacity ease-in-out duration-300`;
	const sharedButtonClasses = `ml-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex h-8 w-8`;

	$: bodyColorClasses = getColorClasses(color);
	$: buttonColorClasses = getButtonColorClasses(color);

	$: bodyClasses = `${sharedBodyClasses} ${bodyColorClasses}`;
	$: buttonClasses = `${sharedButtonClasses} ${buttonColorClasses}`;

	const getColorClasses = (color) => {
		switch (color) {
			case 'blue':
				return 'bg-blue-100 text-blue-700';
			case 'red':
				return 'bg-red-100 text-red-700';
			case 'green':
				return 'bg-green-100 text-green-700';
			case 'yellow':
				return 'bg-yellow-100 text-yellow-700';
			case 'indigo':
				return 'bg-indigo-100 text-indigo-700';
			case 'purple':
				return 'bg-purple-100 text-purple-700';
			case 'pink':
				return 'bg-pink-100 text-pink-700';
			case 'gray':
				return 'bg-gray-100 text-gray-700';
			default:
				return 'bg-blue-100 text-blue-700';
		}
	};

	const getButtonColorClasses = (color) => {
		switch (color) {
			case 'blue':
				return 'bg-blue-100 text-blue-500 focus:ring-blue-400 hover:bg-blue-200';
			case 'red':
				return 'bg-red-100 text-red-500 focus:ring-red-400 hover:bg-red-200';
			case 'green':
				return 'bg-green-100 text-green-500 focus:ring-green-400 hover:bg-green-200';
			case 'yellow':
				return 'bg-yellow-100 text-yellow-500 focus:ring-yellow-400 hover:bg-yellow-200';
			case 'indigo':
				return 'bg-indigo-100 text-indigo-500 focus:ring-indigo-400 hover:bg-indigo-200';
			case 'purple':
				return 'bg-purple-100 text-purple-500 focus:ring-purple-400 hover:bg-purple-200';
			case 'pink':
				return 'bg-pink-100 text-pink-500 focus:ring-pink-400 hover:bg-pink-200';
			case 'gray':
				return 'bg-gray-100 text-gray-500 focus:ring-gray-400 hover:bg-gray-200';
			default:
				return 'bg-blue-100 text-blue-500 focus:ring-blue-400 hover:bg-blue-200';
		}
	};

	// Lifecycle

	let currentPath;

	$: if (browser && currentPath !== $page.url.pathname) {
		currentPath = $page.url.pathname;
		closeBanner();
	}

	onMount(() => {
		currentPath = $page.url.pathname;

		if (show && document?.getElementById('global-top-banner')) {
			offsetHeight = document?.getElementById('global-top-banner')?.clientHeight;
		}
	});
</script>

<svelte:window bind:scrollY={y} />

{#if show && message?.length}
	{#if floating}
		<div style="height: {offsetHeight}px; width: 100%;" />
	{/if}

	<div
		id="global-top-banner"
		class:opacity-100={show}
		class:hidden={!show}
		class:opacity-0={!show}
		class={bodyClasses}
		class:sticky={floating}
		style="z-index: 1000; {offsetStyle}"
	>
		{#if showPulseCircle}
			<div class="grid w-6 content-center">
				<div class="relative h-4 w-4">
					<div class="absolute top-0">
						<CircleIcon class="h-4 w-4 animate-ping" fill="currentColor" />
					</div>
					<div class="absolute">
						<CircleIcon class="h-4 w-4" fill="currentColor" />
					</div>
				</div>
			</div>
		{/if}
		<div class="w-full text-sm font-medium text-center">
			{@html message}
		</div>
		{#if showClose}
			<button type="button" class={buttonClasses} aria-label="Close" on:click={closeBanner}>
				<CloseIcon class="h-5 w-5" />
			</button>
		{/if}
	</div>
{/if}

<style>
	.sticky {
		position: fixed;

		top: 0px;
		left: 0px;
		width: 100%;

		display: block;
		z-index: 20;

		border-radius: 0px;
		border: none;

		transition: box-shadow 300ms ease-out, width var(--transition-duration) ease-in;
	}
</style>
