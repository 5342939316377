<script>
	import { createEventDispatcher, onMount } from 'svelte';
	import { goto } from '$app/navigation';
	import { page } from '$app/stores';
	import { session } from '$app/stores';

	import BugReportModal from '$lib/components/Misc/BugReport/BugReportModal.svelte';
	let openReportBugModal;

	import HomeIcon from '$lib/components/Icons/HomeIcon.svelte';
	import CautionIcon from '$lib/components/Icons/CautionIcon.svelte';
	import SearchIcon from '$lib/components/Icons/SearchIcon.svelte';
	import CreditCardIcon from '$lib/components/Icons/CreditCardIcon.svelte';

	let open = false;
	let menuWidth;
	let interacted = false;
	let mounted = false;
	let isMob = false;
	let clientHeight = 0;

	onMount(() => {
		isMob = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
		clientHeight = window.innerHeight;
		mounted = true;
	});
</script>

<!-- If the page is not / -->

{#if mounted && $session?.user?.email && $page.routeId != ''}
	<div
		class="fixed {isMob ? 'top-96' : 'top-1/3'}  transform-gpu"
		class:transition-all={interacted}
		class:duration-300={interacted}
		class:ease-in-out={interacted}
		style="
			{isMob ? `top: ${clientHeight / 2 - 50}px;` : `top 50%;`}
			{open ? `transform: translateX(${0});` : `transform: translateX(-${menuWidth}px);`}
			z-index: 999; 
		"
	>
		<div
			class="flex transform-gpu flex-row items-center gap-0 transition-all duration-300 ease-in-out"
			style="
      --dock-background-color: rgba(69,69,69,0.4);
      --dock-border-color: hsla(0,0%,100%,0.1);
      --dock-divider-color: hsla(0,0%,100%,0.1);
      --dock-left-open-button-icon-color: hsla(0,0%,100%,0.8);   
    "
		>
			<!-- Menu Bar -->
			{#if mounted}
				<div
					class="border-l-0.5 border-r-0.5 border-t0 flex cursor-pointer flex-col items-center justify-center gap-3 border-b border-white/50 bg-gray-500/50 p-3"
					style="
        box-shadow: 0 0 20px rgba(0,0,0,.4);
        backdrop-filter: blur(20px) saturate(180%);
        background-color: var(--dock-background-color);
        border: 1px solid var(--dock-border-color);
        border-radius: 0 16px 16px 0;
        padding: 12px 11px 12px 13px;
      "
					bind:clientWidth={menuWidth}
				>
					<!-- Home Button -->
					<div
						class="duration-50 rounded-2xl bg-gradient-to-br from-indigo-700 to-indigo-500 p-2 transition-all ease-in-out hover:scale-110 focus:scale-110"
						style="width: 60px; height: 60px;"
						on:click={() => {
							interacted = true;
							goto('/modules');
							open = false;
						}}
					>
						<HomeIcon class="mx-auto h-full w-full text-white" strokeWidth={2} />
					</div>

					<!-- Find Item Button -->
					<div
						class="duration-50 rounded-2xl bg-gradient-to-br from-green-700 to-lime-500 p-2 transition-all ease-in-out hover:scale-110 focus:scale-110"
						style=" width: 60px; height: 60px;"
						on:click={() => {
							interacted = true;
							open = false;

							// Open a new tab at /search/search-item
							window.open('/search/search-item', '_blank');
						}}
					>
						<SearchIcon class="mx-auto h-full w-full text-white" strokeWidth={2} />
					</div>

					<!-- Add Gift Card Button -->
					<div
						class="duration-50 rounded-2xl bg-gradient-to-br from-blue-700 to-teal-500 p-2 transition-all ease-in-out hover:scale-110 focus:scale-110"
						style=" width: 60px; height: 60px;"
						on:click={() => {
							interacted = true;
							open = false;

							// Open a new tab at /fulfillment/gift-cards/add
							window.open('/fulfillment/gift-cards/add', '_blank');
						}}
					>
						<CreditCardIcon class="mx-auto h-full w-full text-white" strokeWidth={2} />
					</div>

					<!-- Bug Report Button -->
					<div
						class="duration-50 rounded-2xl bg-gradient-to-br from-amber-500 to-yellow-500 p-2 transition-all ease-in-out hover:scale-110 focus:scale-110"
						style="width: 60px; height: 60px;"
						on:click={() => {
							interacted = true;
							openReportBugModal();
							open = false;
						}}
					>
						<CautionIcon class="mx-auto h-full w-full text-white" strokeWidth={2} />
					</div>
				</div>
			{/if}

			<!-- Toggle Button -->
			<div
				class="flex h-min cursor-pointer flex-col items-center justify-center"
				style="
        height: 70px; 
        width: 30px;
        box-shadow: 0 0 20px rgba(0,0,0,.4);
        backdrop-filter: blur(20px) saturate(180%);
        background-color: var(--dock-background-color);
        border-radius: 0 7px 7px 0; 
        border: 1px solid var(--dock-border-color);
		"
				on:click={() => {
					interacted = true;
					open = !open;
				}}
			>
				<div class="transition-all duration-200 ease-in-out hover:scale-110 focus:scale-110" class:rotate-180={open}>
					<svg
						width="8"
						height="19"
						viewBox="0 0 8 19"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						style="hsla(0,0%,100%,0.8);"
					>
						<path
							d="M1 1.78613L6.55082 9.19549C6.81706 9.55087 6.81706 10.0393 6.55082 10.3946L1 17.804"
							stroke="white"
							stroke-width="2"
							stroke-linecap="round"
						/>
					</svg>
				</div>
			</div>

			<!--  -->
		</div>
	</div>

	<BugReportModal bind:openModal={openReportBugModal} />
{/if}
